<template>
  <div class="modal" style="display:block">

        <div class="modal-content">
            <div class="indice">
                <p v-html="item.message"></p>
                <img
                    :src="item.image"
                    :alt="item.label">
            </div>
        </div>

        <div class="modal-footer">
            <button v-if="!isAlreadyInDossier && item.readonly === undefined" type="button" class="btn btn-blue align-center" @click="addToRessources()"><span>Ajouter au dossier Ressources</span></button>

            <button v-else type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button>
        </div>

    </div>
</template>

<script>
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import jsonRessources from '@/data/ressources_objects.json'
import { EventBus } from '@/core/event-bus.js';
import { GAME_EVENT } from '@/core/gameConfig'

export default {
  name: 'RessourceViewer',
  data(){
      return {
        //   showButton: true,
        //   slideIdx: 0,
        //   sliderLength: 3
      }
  },
  props : [
    'options'
  ],
  mounted() {
    //   if(this.options == 'algorithme-erreur'){
    //       this.showButton = false
    //   }
  },
  components: {
    // VueSlickCarousel
  },
  computed: {
    item() {
      let res = jsonRessources.find( r => {
          return r.label == this.options
      })
      return res
    },
    itemsInDossier() {
      return this.$store.state.dossiers.ressources
    },
    isAlreadyInDossier() {
      return this.itemsInDossier.indexOf(this.options) !== -1
    },
  },
  methods : {
    close() {
      this.$emit('close')
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev() {
      this.$refs.carousel.prev()
    },
    addToRessources() {
      this.$store.commit("addToDossier", {dossier: 'ressources', item: this.options})
      this.$emit('close')
      EventBus.$emit(GAME_EVENT.ADDRESSOURCE);

    },
    // afterChange(slideIndex) {
    //     console.log('slideIndex', slideIndex)
    //     console.log('length', this.$refs.carousel.$options._renderChildren.length )
    //     this.slideIdx = slideIndex
    //     this.sliderLength = this.$refs.carousel.$options._renderChildren.length
    //     if(slideIndex  == this.$refs.carousel.$options._renderChildren.length - 1) {
    //         this.showButton = true;
    //     }
    // }
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
